@import '../../styles/variables';

$card-border-radius: 8px;

.card {
  background-color: $product-base;
  border-radius: $card-border-radius;
  display: block;
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
  scroll-snap-align: center;
  width: 100%;

  // fix for border radius with child elements in safari
  // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
  transform: translateZ(0);

  /* Prevent background color leak outs */
  -webkit-background-clip: padding-box;
  -moz-background-clip:    padding;
  background-clip:         padding-box;

  @media (min-width: $tablet) {
    scroll-snap-align: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus-visible,
  &:active {
    box-shadow: 0px 4px 8px rgb(152 162 175 / 25%);
  }
}

.camPlayerPlaceholder {
  aspect-ratio: 16/9;
  background-color: $background;
}

.camPlayerLabel {
  display: flex;
  min-height: 113px;
  padding: 15px;

  @media (min-width: $tablet) {
    min-height: 125px;
  }

  @media (min-width: $tabletLg) {
    min-height: 127px;
    padding: 20px;
  }

  .spotName {
    color: $gray20;
    font-weight: 600;
    margin-bottom: 4px;
    overflow: hidden;
    padding-bottom: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    @media (min-width: $tabletLg) {
      padding-bottom: 4px;
    }
  }
}

.sideThumbnailLabel {
  padding: 10px;
}

.sideThumbnail {
  border-radius: 8px;
  height: 80px;
  margin-right: 10px;
  min-width: 142px;
  overflow: hidden;
  position: relative;
  width: 142px;
}

@media (max-width: $tabletLg) {
  .sideThumbnailLabel {
    :global {
      h6 {
        height: unset;
      }

      h4 {
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      figure {
        background-color: transparent;
        position: unset;
      }

      .current-wave-height-and-rating {
        align-items: center;
      }
    }
  }
}

.iconsWrapper {
  display: flex;
  justify-content: flex-end;
}

.link {
  color: $textPrimary;
  text-decoration: none;
  outline: none;
  width: 100%;

  &:hover,
  &:focus-visible {
    :global {
      .MuiTypography-title2,
      .MuiTypography-headline {
        color: $gray10;
      }
      .MuiTypography-caption2 {
        color: $gray30;
      }
    }

    .spotName {
      color: $gray10;
    }
  }
}

// This has to be nested so the specificity overrides a Mui rule
.withClarity {
  .camPlayerLabel {
    padding-top: 12px;
    padding-bottom: 16px;
    .waveRatingWrapper {
      margin-top: 0;
      margin-bottom: 4px;
    }
    .emptyWaveRatingWrapper {
      height: 16px;
      margin-top: 0;
      margin-bottom: 4px;
    }

    .observations {
      margin-top: 4px;
    }
  }
}

.small {
  .waveRatingWrapper {
    margin-top: 0;
  }
  .observations {
    margin-top: 0;
  }
}
